import { SharedService } from 'src/app/_services/shared.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MobileService } from 'src/app/_services/mobile-service.service';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.css']
})
export class DetailsDialogComponent implements OnInit,OnDestroy {

  @Input() transformOriginClass: string;
  @Input() title: string;
  @Input() titleColor: string;
  @Input() showBack = false;
  @Input() showFooter = false;
  @Input() showBigFooter = false;
  @Input() footerButtonText = '';
  @Input() disabledButton = false;
  @Input() dialogContentClass = '';
  @Input() showFullZise = false;
  @Output() onBackClick = new EventEmitter<boolean>();
  @Output() onCloseDialog = new EventEmitter<boolean>();
  @Output() submitFunction = new EventEmitter();

  fullSizeFlag = false;
  constructor(
    private dialogRef: MatDialogRef<DetailsDialogComponent>,
    public shared: SharedService,
    private mobile:MobileService
  ) {
    this.dialogRef.addPanelClass('details-modal');
  }
  ngOnDestroy(): void {
    if(this.mobile.isMobileView){
      document.getElementsByClassName('cdk-overlay-container')[0].innerHTML = '<div class=""></div>';
    }
  }
  closeDialog() {
    if (this.onCloseDialog.observers.length > 0) {
      this.onCloseDialog.emit();
    } else {
      this.dialogRef.close(false);
    }
  }

  backClick() {
    this.onBackClick.emit(true);
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitFunction.emit();
  }

  fullsize() {
    if (this.fullSizeFlag)
      this.dialogRef.removePanelClass('xxLarge');
    else
      this.dialogRef.addPanelClass('xxLarge');
    this.fullSizeFlag = !this.fullSizeFlag;
  }

}
