import { pipe } from 'rxjs';

import { SharedService } from './../../../_services/shared.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MobileService } from '../../../_services/mobile-service.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css']
})
export class FormDialogComponent implements OnInit,OnDestroy {

  @Input() title: string;
  @Input() buttonText: string;
  @Input() titleColor: string;
  @Input() cssClass = '';
  @Input() closeIcon = 'close';
  @Input() rightIcon = '';
  @Input() Hidebuttons = false;
  @Input() showBigFooter = false;
  @Input() disabledButton = true;
  @Input() button2Text = '';
  @Input() heightClass = '';
  @Input() showFooter: boolean = true;
  @Input() showFullZise = false;

  @Input() transformOriginClass: string;

  @Output() closeDialog = new EventEmitter();
  @Output() submitFunction = new EventEmitter();
  @Output() submit2Function = new EventEmitter();
  fullSizeFlag: any;

  constructor(
    private dialogRef: MatDialogRef<FormDialogComponent>,
    private shared: SharedService,
    private mobile: MobileService

  ) {
      this.dialogRef.addPanelClass('edit-modal');
     /*  this.dialogRef.beforeClosed().subscribe(data=> {
        this.close();

      }); */
     // this.dialogRef.backdropClick().subscribe(() => { this.close(); });


  }
  ngOnDestroy(): void {
    if(this.mobile.isMobileView){
      document.getElementsByClassName('cdk-overlay-container')[0].innerHTML = '<div class=""></div>';
    }
  }
  close() {
    return false;
    /* this.confirm.open({ title: 'Would you like to acknowledge this message?' }).pipe(
      tap(answer => { if (answer === true) { this.dialogRef.close(); } }),
    ).subscribe(); */
}

  onSubmit(): void {

    this.submitFunction.emit(null);
  }
  onButton2Click(): void {
    this.submit2Function.emit(null);
  }

  ngOnInit(): void {
    if (!this.shared.isEmpty(this.heightClass) && !this.mobile.isMobileView ) {
      this.dialogRef.addPanelClass(this.heightClass);
    }
  }
  closedialogEvent() {
    if (this.closeDialog.observers.length === 0) {
      this.dialogRef.close(false);
    } else {
      this.closeDialog.emit();
    }
  }

  fullsize() {
    if (this.fullSizeFlag)
      this.dialogRef.removePanelClass('xxLarge');
    else
      this.dialogRef.addPanelClass('xxLarge');
    this.fullSizeFlag = !this.fullSizeFlag;
  }

}
