import jsPDF from 'jspdf';
import { PdfExportData } from './PdfExportData';
import { FilesService } from './files.service';
import { Injectable } from '@angular/core';
import 'src/assets/fonts/he/OpenSansHebrew-Regular-normal.js'
import 'src/assets/fonts/he/OpenSansHebrew-Bold-normal.js'
import autoTable from 'jspdf-autotable'
import { SafeHtmlPipe } from '../_pipes/safeHtml';
import * as _ from 'underscore';


@Injectable({
  providedIn: 'root'
})
export class PdfExportService {


  constructor(
    private filesService: FilesService,
    private safeHtml: SafeHtmlPipe
  ) {
  }

  SavePDFNew(fileName: string, path: string, skipPage1 = false, maxPages = 20,skipPages = 0): void {
    let data = new PdfExportData();
    data.fileName = fileName;
    data.skipPage1 = skipPage1;
    data.skipPages = 2;
    data.path = path;
    data.maxPages = maxPages;
    data.exportToPdf = true;
    this.filesService.downloadPdf(data, fileName);
  }

  flipBrackets(data) {
    data = data.replaceAll('(', '**--**');
    data = data.replaceAll(')', '(');
    data = data.replaceAll('**--**', ')');
    return data;
  }

  SavePdfPersonalEducationProgram(fileName: string, header1: string, header2: string = '', jsonData, splitBy1, splitBy2, lastUpdateHeader
    , img = null, signRow = 0, mainView: any[] = []) {

    header1 = this.flipBrackets(header1);

    const doc = new jsPDF('l', 'mm', [297, 210]);
    doc.addImage(img, 'PNG', 265, 2, 15, 15);

    (doc as any).autoTable.previous = false;
    doc.setR2L(true);
    doc.setFont('OpenSansHebrew-Bold');
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text(header1, pageWidth / 2, 10, { align: 'center' });
    if (header2 != '') {
      doc.setFontSize(8);
      doc.text(header2, 280, 20, { align: 'right' });
    }
    let headers = [Object.keys(jsonData[0]).reverse()];
    headers[0] = headers[0].filter(x => x != splitBy1);
    headers[0] = headers[0].filter(x => x != splitBy2);
    headers[0] = headers[0].filter(x => x != lastUpdateHeader);
    let data = [];
    let drawHeader = '';

    let topHeaders = Object.keys(_.countBy(jsonData, function (data) { return data[splitBy1].text; })).slice(0, -4 - signRow);
    let firstSpace = 25;
    topHeaders.forEach(topHeader => {
      doc.setFont('OpenSansHebrew-Bold');
      doc.setDrawColor(0, 0, 0, 0.05)
      doc.setFontSize(9);

      doc.text(topHeader, 280, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 10, { align: 'right' });
      doc.line(10, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 13,
        287, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 13,
      )
      let list1 = _.filter(jsonData, function (data) { return data[splitBy1].text == topHeader });
      let secondHeaders = Object.keys(_.countBy(list1, function (data) { return data[splitBy2].text; }));
      let x = 0;
      secondHeaders.forEach(secondHeader => {
        doc.setFontSize(8);
        let listData = _.filter(list1, function (data) { return data[splitBy2].text == secondHeader })
        let lastUpdate = this.flipBrackets(listData[0][lastUpdateHeader].text)
        doc.addImage(location.origin + '/assets/images/goal.png', 'PNG', 276, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 16, 5, 5);

        doc.text(`${secondHeader}, )${lastUpdateHeader}: ${lastUpdate}(`, 275, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 20, { align: 'right' });
        doc.setDrawColor(0, 0, 0, 0.08)

        doc.line(10, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23,
          287, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23,
        )
        for (let index = 0; index < listData.length; index++) {
          const element = listData[index];
          let row: any[] = [];
          for (let j = Object.keys(jsonData[0]).length - 2; j >= 0; j--) {
            const key = Object.keys(jsonData[0])[j];
            let value = this.safeHtml.transform(this.checkText(element[key])).toString();
            row.push(value)
          }
          data.push(row);
        }
        this.drawPdf(doc, data, headers, drawHeader, {}, false, firstSpace);
        firstSpace = 10;
        data = [];
      });
    });

    headers = [Object.keys(jsonData[0]).reverse()]

    let element = _.filter(jsonData, function (data) { return data[headers[0][headers[0].length - 1]].text == mainView[0] })[0];
    doc.setFont('OpenSansHebrew-Bold');
    doc.text(`${element[headers[0][headers[0].length - 1]].text}`, 280, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 20, { align: 'right' });
    doc.line(200, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23,
      280, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23);
    doc.setFont('OpenSansHebrew-Regular');
    doc.text(`${element[headers[0][headers[0].length - 2]].text}`, 280, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 30, { align: 'right' });

    element = _.filter(jsonData, function (data) { return data[headers[0][headers[0].length - 1]].text == mainView[1] })[0];
    doc.setFont('OpenSansHebrew-Bold');
    doc.text(`${element[headers[0][headers[0].length - 1]].text}`, 185, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 20, { align: 'right' });
    doc.line(105, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23,
      185, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23);
    doc.setFont('OpenSansHebrew-Regular');
    doc.text(`${element[headers[0][headers[0].length - 2]].text}`, 185, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 30, { align: 'right' });

    element = _.filter(jsonData, function (data) { return data[headers[0][headers[0].length - 1]].text == mainView[2] })[0];
    doc.setFont('OpenSansHebrew-Bold');
    doc.text(`${element[headers[0][headers[0].length - 1]].text}`, 90, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 20, { align: 'right' });
    doc.line(90, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23,
      10, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 23);

    doc.setFont('OpenSansHebrew-Regular');
    doc.text(`${element[headers[0][headers[0].length - 2]].text}`, 90, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 30, { align: 'right' });


    if (signRow == 1) {
      doc.text(`${jsonData[jsonData.length - 1][headers[0][headers[0].length - 1]].text}`, 280, ((doc as any).autoTable.previous == false ? 20 : (doc as any).autoTable.previous.finalY) + 50, { align: 'right' });
    }


    doc.save(fileName)

  }

  SavePDFTable(fileName: string, header: string, jsonData, splitBy = '', removeLastRow = false,
    colWidth = {}, img = null, lastRowIsBold = false, hideHeaders = false, showImgOnTop = false) {
    const doc = new jsPDF('l', 'mm', [297, 210]);


    if (img != undefined && img != null && showImgOnTop) {
      doc.addImage(img, 'PNG', 265, 2, 15, 15);
    }

    (doc as any).autoTable.previous = false;
    doc.setR2L(true);
    doc.setFont('OpenSansHebrew-Bold');

    //doc.setFont('OpenSansHebrew-Regular');
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text(header, pageWidth / 2, 10, { align: 'center' });


    let data = [];
    let drawHeader = '';

    let headers = [Object.keys(jsonData[0]).reverse()];
    headers[0] = headers[0].filter(x => x != splitBy);
    if (hideHeaders) {
      headers = null;
    }
    let lastVal: any = '';;
    if (splitBy && jsonData.length > 0) {
      lastVal = jsonData[0][splitBy];
      if (lastVal.text != undefined) {
        lastVal = lastVal.text
      }
    }
    let draw = false;
    for (let index = 0; index < jsonData.length - (removeLastRow ? 1 : 0); index++) {
      const element = jsonData[index];
      let row: any[] = [];
      for (let j = Object.keys(jsonData[0]).length - 1; j >= 0; j--) {
        const key = Object.keys(jsonData[0])[j];
        let value = this.safeHtml.transform(this.checkText(element[key])).toString();
        if (splitBy == key) {
          if (lastVal != '' && lastVal != value) {
            drawHeader = lastVal;
            lastVal = value;
            draw = true;
          } else {
            lastVal = value;

          }
        } else {

          row.push(value)
          //row.push(doc.splitTextToSize(value,20,' '))
        }
      }

      if (draw) {
        if (data.length > 0) {
          this.drawPdf(doc, data, headers, drawHeader, colWidth, hideHeaders);
        }
        draw = false;
        data = [];
        data.push(row);
      } else {
        data.push(row);
      }

    }

    if (data.length > 0)
      this.drawPdf(doc, data, headers, drawHeader == '' ? '' : lastVal, colWidth, hideHeaders);
    if (img != undefined && img != null && !showImgOnTop) {
      doc.addImage(img, 'PNG', 10, (doc as any).autoTable.previous.finalY + 10, 280, 50);
      //doc.addImage(img, 'PNG', 10, 10, 100, 100);

    }
    doc.save(fileName)
  }

  drawPdf(doc, data, headers, title = '', colWidth, hideHeaders, topSpace = 0) {
    if (title != '') {
      doc.setFont('OpenSansHebrew-Bold');
      doc.setFontSize(8);

      doc.text(title, 280, (doc.autoTable.previous == false ? 20 : doc.autoTable.previous.finalY) + 10 + topSpace, { align: 'right' });
      doc.line(10, (doc.autoTable.previous == false ? 20 : doc.autoTable.previous.finalY) + 13 + topSpace,
        287, (doc.autoTable.previous == false ? 20 : doc.autoTable.previous.finalY) + 13 + topSpace,
      )
    }

    autoTable(doc, {
      theme: 'plain',
      head: headers,
      startY: (doc.autoTable.previous == false ? title == '' ? 5 : 20 : doc.autoTable.previous.finalY) + 13 + topSpace,
      columnStyles: colWidth,
      //     tableWidth:pageWidth,

      willDrawCell: function (data) {
        // add borders around the head cells

        doc.setDrawColor(0, 0, 0, 0.1); // set the border color
        doc.setLineWidth(0.1); // set the border with

        if (data.row.section === "head") {
          if (!hideHeaders) {

            doc.setFont('OpenSansHebrew-Bold');
            doc.setFontSize(8);

            //doc.setDrawColor('#000000b3'); // set the border color
            doc.setLineWidth(0.1); // set the border with

            // draw bottom border
            doc.line(
              data.cell.x,
              data.cell.y + data.cell.height,
              data.cell.x + data.cell.width,
              data.cell.y + data.cell.height
            );
            doc.setTextColor(0, 0, 0, 0.7)
          }
        } else {
          doc.line(
            data.cell.x,
            data.cell.y,
            data.cell.x + data.cell.width,
            data.cell.y
          );
          doc.setTextColor(0, 0, 0);
          doc.setFont('OpenSansHebrew-Regular');
          doc.setFontSize(8);
        }

      },
      body:
        data
      , styles: { font: "OpenSansHebrew-Regular", halign: 'right', valign: 'middle', cellPadding: 3 }
    })
  }

  checkText(text) {

    if (text == '' || text == null || text == undefined) return '';
    if ((typeof text) != 'string') {
      text = text.text;
      if (text == '' || text == null || text == undefined) return '';
    }

    if (text.match(/[א-ת]+/ig)) {
      text = text.replaceAll('(', '**##**##');
      text = text.replaceAll(')', '(');
      text = text.replaceAll('**##**##', ')');
      text = text.replaceAll('[', '**##**##');
      text = text.replaceAll(']', '[');
      text = text.replaceAll('**##**##', ']');
      //   text = ;
      return text;
    }

    let arr = text.match(/[a-z0-9@.-:%]+/ig) ?? '';

    let pos = 0;
    let a = [];
    for (let i = 0; i < arr?.length; i++) {
      let t = arr[i];
      pos += t.length;
      a.push({ from: text.indexOf(t), to: text.indexOf(t) + t.length })
    }

    for (let i = 0; i < a?.length; i++) {
      let rep = a[i];
      let s = text.substring(rep.from, rep.to);
      var splitString = s.split("");
      var reverseArray = splitString.reverse();
      var joinArray = reverseArray.join("");
      text = text.replace(s, joinArray);
    }
    text = text.replaceAll('(', '**##**##');
    text = text.replaceAll(')', '(');
    text = text.replaceAll('**##**##', ')');
    text = text.replaceAll('[', '**##**##');
    text = text.replaceAll(']', '[');
    text = text.replaceAll('**##**##', ']');


    return text;
  }

}

