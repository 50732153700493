import { SqlService } from './sqlService';
import { LogService } from './logService';
//import { baseResult } from 'src/app/_models/baseResult';
import { CookiesService } from './cookies-service.service';
import { MobileService } from './mobile-service.service';
import { RsaService } from './Rsa.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { map, catchError, take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './user-service.service';
import { Observable } from 'rxjs-compat';
import { PdfExportData } from './PdfExportData';
import { baseResult } from '../_models/baseResult';
import { SnackBarComponent } from '../common/snack-bar/snack-bar.component';


@Injectable({ providedIn: 'root' })
export class PostService {
  apiUrl = '';
  apiUrlImage = '';
  deviceId = '';
  constructor(
    private http: HttpClient,
    private route: Router,
    private user: UserService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private rsa: RsaService,
    private logService: LogService,
    private mobileService: MobileService,
    private sqlService: SqlService,
    private cookieService: CookiesService,
  ) {
    this.getUrl();
    this.getLastRelease()

    sqlService.getByKey('deviceId').subscribe(result => {
      if (result != undefined) {
        this.deviceId = result['value'];
      }
    })
  }
  isTestMode = false;

  getUrl() {

    switch (window.location.host.toLowerCase()) {
      case 'localhost:4200':
        this.apiUrlImage = this.apiUrl = 'https://localhost:44389';
        this.isTestMode = true;

        break;
      case 'testclient.smartschool.co.il':
        this.apiUrl = 'https://testserver.smartschool.co.il/server';
        this.apiUrlImage = 'https://testserver.smartschool.co.il/serverImages';
        this.isTestMode = true;
        break;
      case 'webtop.smartschool.co.il':
        this.apiUrl = 'https://webtopserver.smartschool.co.il/server';
        this.apiUrlImage = 'https://webtopserver.smartschool.co.il/serverImages';
        break;
    }
  }

  siteReset() {
    //return false;

    try {
      const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
      this.http.get('/assets/version/version.txt?dt=' + (new Date()).getTime(), { headers, responseType: 'text' }).subscribe(data => {
        // console.log(data);


        let lastRelease = data.substring(0, 13);
        //console.log('Last release timestamp is set to:',lastRelease);
        let version = localStorage.getItem('version');
        if (version === undefined || version === null) {
          localStorage.setItem('version', lastRelease)
          window.location.reload();
          return true;
        } else if (parseFloat(version) < parseFloat(lastRelease)) {
          localStorage.setItem('version', lastRelease)
          window.location.reload();

          return true;
        }
        return false;

      })
    } catch (error) {
      console.log(error);
    }

    return false;

  }

  getLastRelease(): string {


    return '';
  }


  simpleGet(url) {

    this.http.get(url);
  }



  /* ********************  */



  postData<T>(url: string, useToken: boolean, postParams: any, useTokenIfExist: boolean = false,
    checkData: boolean = true, rememberMe: boolean = false, retry: boolean = false): Observable<baseResult<T>> {
    const res = new Observable<baseResult<T>>();
    res.pipe(take(1)).subscribe(data => { data.status = false; })
    if (this.siteReset()) { return null; }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'language': this.cookieService.getItem('lang') ?? localStorage.getItem('language'),
        'rememberMe': rememberMe ? this.rsa.encryptStringToServer('remeberMe') : '0'
      })
      ,
      withCredentials: true,
      observe: 'response' as 'body'

    };
    localStorage.setItem('lastPost', (new Date()).getTime().toString());
    let user = this.user.getUser()
    if (user != null) {
      let itemsToSave = ['studyYear', 'gradesSelected', 'tabId'];
      let arr = [];
      const randomNumber = user.userId + user.id;
      itemsToSave.forEach(x => arr[x] = sessionStorage.getItem(x));
      sessionStorage.clear();
      sessionStorage.setItem('tabId', randomNumber);
      itemsToSave.forEach(x => {
        if (arr[x] != null && arr[x] != undefined)
          sessionStorage.setItem(x, arr[x])
      }
      );
    }
    let deviceType = this.cookieService.getItem('deviceType');
    try {
      if (this.isTestMode) //window.alert(`deviceType: ${deviceType}, url: ${url}`);
      {
        try {
          //@ts-ignore
          webkit.messageHandlers.MobileDevice.postMessage({ action: 'displayNotification', data: `deviceType: ${deviceType}, url: ${url}, deviceId: ${this.deviceId}` });
        } catch (error) {

        }
      }

      //this.logService.writeToLog(this.apiUrl, url, `deviceId: ${this.deviceId}`, JSON.stringify(postParams), deviceType);

    }
    catch { }

    return this.http.post<baseResult<T>>(`${this.apiUrl}/${url}`, postParams, httpOptions).pipe(
      map((data: baseResult<T>) => {
        if (!checkData) {
          return;
        }
        if (this.user.getUser() === null) {
          console.log('User is null !!!!!!!!!!!!!!    ' + url);
        }

        try {
          if (deviceType == 'ios') {
            this.logService.writeToLog(this.apiUrl, JSON.stringify(data), '', '', 'iosDevice');
          }
        }
        catch { }


        if (data.status) {
          this.user.updateLastToken();
          this.cookieService.removeItem('errorCounter');
        } else {
          if (data.errorDescription === 'Not Allow') {// No permissions of pupil card
            window.location.href = window.location.origin;
            return;
          } else if (data.errorDescription.includes('You are not authorized to use this service')) {
            window.location.href = window.location.origin + '/error/notAllowed';
            return;
          }
          this.cookieService.removeItem('errorCounter');
          console.log(url);
        }
        console.log('postData=> ' + url);
        console.log(data);




        return data['body'];
      }), catchError(error => {
        if (this.isTestMode) {
          try {
            //@ts-ignore
            webkit.messageHandlers.MobileDevice.postMessage({ action: 'displayNotification', data: `deviceType: ${deviceType}, deviceId: ${this.deviceId}, error: ${error.message}, ${error.error}` });
          } catch {

          }
        }
        //window.alert(`deviceType: ${deviceType}, error: ${error.message}, ${error.error}`);
        try {

          this.logService.writeToLog(this.apiUrl, JSON.stringify(user), JSON.stringify(error), this.deviceId);
        }
        catch (err) {
          try {
            //@ts-ignore
            webkit.messageHandlers.MobileDevice.postMessage({ action: 'displayNotification', data: JSON.stringify(err) });
          } catch {

          }
        }

        if (error.status == 0 && error.statusText == 'Unknown Error') {
          //this.postDataOnError(url,useToken,postParams,useTokenIfExist,checkData,rememberMe,retry);
        }
        if ((error.message.toLowerCase().includes('access-control-allow-origin') || error?.error?.toLowerCase().includes('access-control-allow-origin')) && !retry) {
          // console.log(error.error);
          return this.postData<T>(url, useToken, postParams, useTokenIfExist, checkData, rememberMe, true);
        }

        if (error?.error === 'Token validation process failed: Token is expired, Please do relogin') {
          console.log(error.error);
          this.user.clear(true, false);
          // this.route.navigate(['/account/login']);
          window.location.href = window.location.origin + '/account/login';
          return;
        }



        if (error?.error?.includes('You are not authorized to use this service')) {
          window.location.href = window.location.origin + '/error/notAllowed';
          return;
        }
        /* if (error.status === 0 && error?.message?.includes('Http failure response for')) {
          //this.user.clear();
          //window.location.href = window.location.origin;
        }
        else  */
        if (error.status === 500 && (
          error?.error.toString().includes('Token not valid') ||
          error?.error.toString().includes('Token is expired, Please do relogin')

        )) {
          this.user.clear(false, false);
          window.location.href = window.location.origin + '/account/login';
        }
        else if (error.status === 401) {
          // this.route.navigate(['/account/login']);
          try {
            this.user.clear(false,false);
          } catch (error) {

          }
          window.location.href = window.location.origin + '/account/login';
        } else {
          const errorCounter = this.cookieService.getItem('errorCounter');
          if (errorCounter === undefined || errorCounter === null) {
            this.cookieService.setItem('errorCounter', '0');
          } else {
            if (parseInt(errorCounter, 10) >= 3) {
              this.user.clear();
              window.location.href = window.location.origin + 'account/login';
              //this.route.navigate(['/account/login']);


            } else {
              this.cookieService.setItem('errorCounter', (parseInt(errorCounter, 10) + 1).toString());
            }
          }

          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3000,
            data: { message: this.translate.instant('DefaultLoadDataError'), type: 'warning' }
          });
          return throwError('ooops');
          if (error.statusText === 'Unknown Error') {
            this.route.navigate(['/']);
          }
          console.log('oops');
          console.log(error.error);
          return throwError('ooops');
        }
      }));

  }


  postDataImage<T>(url: string, useToken: boolean, postParams: any, useTokenIfExist: boolean = false,
    checkData: boolean = true, rememberMe: boolean = false, retry: boolean = false): Observable<baseResult<T>> {
    const res = new Observable<baseResult<T>>();
    res.pipe(take(1)).subscribe(data => { data.status = false; })
    if (this.siteReset()) { return null; }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'language': this.cookieService.getItem('lang') ?? localStorage.getItem('language'),
        'rememberMe': rememberMe ? this.rsa.encryptStringToServer('remeberMe') : '0'
      })
      ,
      withCredentials: true,
      observe: 'response' as 'body'

    };

    localStorage.setItem('lastPost', (new Date()).getTime().toString());
    return this.http.post<baseResult<T>>(`${this.apiUrlImage}/${url}`, postParams, httpOptions).pipe(
      map((data: baseResult<T>) => {
        if (!checkData) {
          return;
        }
        if (this.user.getUser() === null) {
          console.log('User is null !!!!!!!!!!!!!!    ' + url);
        }
        if (data.status) {
          this.user.updateLastToken();
          this.cookieService.removeItem('errorCounter');
        } else {
          if (data.errorDescription === 'Not Allow') {// No permissions of pupil card
            window.location.href = window.location.origin + '/account/login';
            return;
          } else if (data.errorDescription.includes('You are not authorized to use this service')) {
            window.location.href = window.location.origin + '/error/notAllowed';
            return;
          }
          this.cookieService.removeItem('errorCounter');
          console.log(url);
        }
        console.log('postData=> ' + url);
        console.log(data);
        return data['body'];
      }), catchError(error => {
        if (error.status == 0 && error.statusText == 'Unknown Error') {
          //this.postDataOnError(url,useToken,postParams,useTokenIfExist,checkData,rememberMe,retry);
        }
        if ((error.message.toLowerCase().includes('access-control-allow-origin') || error?.error?.toLowerCase().includes('access-control-allow-origin')) && !retry) {
          // console.log(error.error);
          return this.postData<T>(url, useToken, postParams, useTokenIfExist, checkData, rememberMe, true);
        }

        if (error?.error === 'Token validation process failed: Token is expired, Please do relogin') {
          console.log(error.error);
          this.user.clear(true, false);
          // this.route.navigate(['/account/login']);
          window.location.href = window.location.origin + '/account/login';
          return;
        }
        /* if (error.status === 0 && error?.message?.includes('Http failure response for')) {
          //this.user.clear();
          //window.location.href = window.location.origin;
        }
        else  */
        if (error.status === 500 && (
          error?.error.toString().includes('Token not valid') ||
          error?.error.toString().includes('Token is expired, Please do relogin')

        )) {
          this.user.clear(false, false);
          window.location.href = window.location.origin + '/account/login';
        }
        else if (error.status === 401) {
          // this.route.navigate(['/account/login']);
          try {
            this.user.clear(false,false);
          } catch (error) {

          }
          window.location.href = window.location.origin + '/account/login';
        } else {
          const errorCounter = this.cookieService.getItem('errorCounter');
          if (errorCounter === undefined || errorCounter === null) {
            this.cookieService.setItem('errorCounter', '0');
          } else {
            if (parseInt(errorCounter, 10) >= 3) {
              this.user.clear();
              window.location.href = window.location.origin + 'account/login';
              //this.route.navigate(['/account/login']);


            } else {
              this.cookieService.setItem('errorCounter', (parseInt(errorCounter, 10) + 1).toString());
            }
          }

          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3000,
            data: { message: this.translate.instant('DefaultLoadDataError'), type: 'warning' }
          });
          return throwError('ooops');
          if (error.statusText === 'Unknown Error') {
            this.route.navigate(['/']);
          }
          console.log('oops');
          console.log(error.error);
          return throwError('ooops');
        }
      }));

  }




  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  public async post<T>(url, useToken, postParams) {

    const res = new baseResult();
    res.status = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'language': this.cookieService.getItem('language'),
      }),
      withCredentials: true,
    };
    try {
      const result = await this.http.post<baseResult<T>>(`${this.apiUrl}/${url}`, postParams, httpOptions).toPromise().then(
        data => {
          if (data.status) {

            this.user.updateLastToken();
          } else {
          }
          this.cookieService.removeItem('errorCounter');
          console.log('postData=> ' + url);
          console.log(data);
          return data;
        },
        error => {
          console.log('oops', error);
          if (error.status === 401) {
            this.user.clear(false,false);
            window.location.href = window.location.origin + '/account/login';
          } else {
            const errorCounter = this.cookieService.getItem('errorCounter');
            if (errorCounter === undefined || errorCounter === null) {
              this.cookieService.setItem('errorCounter', '0');
            } else {
              if (parseInt(errorCounter, 10) >= 5) {
                window.location.href = window.location.origin + 'account/login';
              } else {
                this.cookieService.setItem('errorCounter', (parseInt(errorCounter, 10) + 1).toString());
              }
            }
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('DefaultLoadDataError'), type: 'warning' }
            });
            if (error.statusText === 'Unknown Error') {
              this.route.navigate(['/']);
            }
          }

          return res;
        }
      );
      return result;
    } catch (error) {
      console.log(error);
      return res;
    }
  }

  public postWithFile<T>(url: string, useToken: boolean, formData: any): Observable<HttpEvent<baseResult<T>>> {

    const res = new Observable<HttpEvent<baseResult<T>>>();
    res.pipe(take(1)).subscribe(data => { (data as HttpResponse<baseResult<T>>).body.status === false; });


    try {
      const httpOptions = {
        headers: new HttpHeaders({
        }),
        withCredentials: true,
        //observe: 'response' as 'body'
      };
      const req = new HttpRequest('POST', `${this.apiUrl}/${url}`, formData, httpOptions);

      return this.http.request<baseResult<T>>(req).pipe(
        map((data: HttpEvent<baseResult<T>>) => {
          console.log(data);
          const result = (data as HttpResponse<baseResult<T>>).body;
          if (result !== undefined && result !== null && result.status) {
            this.user.updateLastToken();
          } else {
          }
          return data;
        }), catchError(error => {
          console.log('oops');
          console.log(error);
          if (error.status === 401) {
            // this.route.navigate(['/account/login']);
            /*  this.user.clear();
             window.location.href = window.location.origin; */
          } else {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('DefaultLoadDataError'), type: 'warning' }
            });
            if (error.statusText === 'Unknown Error') {
              //this.route.navigate(['/']);
              /* this.user.clear();
              window.location.href = window.location.origin; */
            }
          }
          return throwError('ooops');
        }));
    } catch (error) {
      console.log(error);
      return res;
    }
  }


  public downloadFile(fileToken: string): Observable<HttpEvent<Blob>> {

    const httpOptions = {
      headers: new HttpHeaders({
      }),
      withCredentials: true,
      //observe: 'response' as 'body'
    };
    return this.http.request(new HttpRequest(
      'GET',
      `${this.apiUrl}/api/stream/GetFile?token=${fileToken}`,
      null
      ,
      {
        reportProgress: true,
        responseType: 'blob',
        withCredentials: true
      }));
  }


  public downloadPdf(postParams: PdfExportData): Observable<HttpEvent<Blob>> {
    postParams.url = window.location.origin;
    const httpOptions = {
      headers: new HttpHeaders({
      }),
      withCredentials: true,
      observe: 'response' as 'body'
    };
    return this.http.request(new HttpRequest(
      'POST',
      `${this.apiUrl}/api/pdf/generate`,
      postParams
      ,
      {
        reportProgress: true,
        responseType: 'blob',
        withCredentials: true
      }));
  }

  randomString(length) {

    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

}
