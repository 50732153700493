import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerService } from '../_services/swipeDownService';
import { SharedService } from '../_services/shared.service';

@Directive({
  selector: '[swipe]'
})
export class SwipeDirectiveDirective {

  constructor(
    private serverService: ServerService
  ) {

  }
  @Output() swipeLeft = new EventEmitter();
  @Output() swipeRight = new EventEmitter();
  @Output() swipeDown = new EventEmitter();
  @Output() swipeUp = new EventEmitter();

  @Input() elem: any = document;

  ngAfterViewInit() {
    this.startOnTouch();
  }

  doSwipeLeft(event) {
    this.swipeLeft.emit(event);
  }

  doSwipeRight(event) {
    this.swipeRight.emit(event);
  }
  doSwipeDown(event) {
    console.log('swipeDown')
    this.swipeDown.emit(event);
    this.serverService.setSwipeDown();

  }

  doSwipeUp(event) {
    console.log('doSwipeUp')
    this.swipeUp.emit(event);
    this.serverService.setSwipeUp();
  }

  defaultTouch = { x: 0, y: 0, time: 0 };

  startOnTouch() {
    const events = [
      'touchstart',
      'touchend',
      'touchcancel',
      /* 'touchmove' */
    ];
    const eventStreams = events.map((ev) => Observable.fromEvent(this.elem, ev));
    const allEvents$ = Observable.merge(...eventStreams);
    const subscription = allEvents$.subscribe((event: TouchEvent) => {
      let touch = event["touches"][0] || event["changedTouches"][0];

      // check the events
      if (event.type === 'touchstart') {
        this.defaultTouch.x = touch.pageX;
        this.defaultTouch.y = touch.pageY;
        this.defaultTouch.time = event.timeStamp;
      } else if (event.type === 'touchend' || event.type === 'touchmove') {

        let deltaX = touch.pageX - this.defaultTouch.x;
        let deltaY = touch.pageY - this.defaultTouch.y;

        let deltaTime = event.timeStamp - this.defaultTouch.time;

        // simulte a swipe -> less than 500 ms and more than 60 px
        if (deltaTime > 500) {
          // touch movement lasted less than 500 ms
          if (Math.abs(deltaX) > 60) {
            // delta x is at least 60 pixels
            if (deltaX > 0) {
              this.doSwipeRight(event);
            } else {
              this.doSwipeLeft(event);
            }
          }

          if (Math.abs(deltaY) > 60) {
            // delta y is at least 60 pixels
            if (deltaY > 0) {
              this.doSwipeDown(event);
            } else {
              this.doSwipeUp(event);
            }
          }
        }
      }
    });
  }
}
