import { SelectComponent } from './../common/UIControls/select/select.component';
import { HebrewYearPipe } from './../_pipes/hebrewYear.pipe';
import { FirstPasswordDialogComponent } from './first-password-dialog/first-password-dialog.component';
import { CookiesService } from './../_services/cookies-service.service';
import { environment } from './../../environments/environment.prod';
import { SharedService } from './../_services/shared.service';
import { UnreadMessagesPreviewComponent } from './unread-messages-preview/unread-messages-preview.component';
import { SettingsComponent } from './../settings/settings.component';
import { Subscription } from 'rxjs/Subscription';
import { DisconnectedAlertComponent } from './../account/disconnected-alert/disconnected-alert.component';
import { PupilCardSharedService } from 'src/app/_services/PupilCardSharedService';
import { MenuFilter, baseResult } from '../_models/baseResult';
import { Countres, MenuDate, MenuItem } from './../_models/MenuItem';
import { Component, OnInit, ViewChild, Injectable, OnDestroy, ElementRef, AfterContentChecked, AfterViewInit, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PostService } from '../_services/postService';
import { MultiUsers, User } from '../_models/user';
import { LoaderService } from '../_services/LoaderService';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../_services/SidenavService';
import { UserService } from '../_services/user-service.service';
import { MobileService } from '../_services/mobile-service.service';
import { MessageBoxInbox, MessageItem } from '../_models/messageBox/MessageBox';
import { CommonService } from '../_services/commonService';
import { NotificationData } from '../_models/Notifications/Notification';
import { HeaderCountersService } from '../_services/headerCounters.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntypedFormControl } from '@angular/forms';
import { GradesService } from '../_services/gradesService.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RsaService } from '../_services/Rsa.service';
import { ServerService } from '../_services/swipeDownService';
import { SqlService } from '../_services/sqlService';
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.css'],
})
export class AppNavComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {


  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    this.setFocus('textLink', 'unset');
    this.setFocus('link-text', 'unset');
    this.setFocus('focus', 'unset');
    this.setFocus('a', 'unset');
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.setFocus('textLink', 'rgba(0, 0, 0, 0.08)');
    this.setFocus('focus', 'rgba(0, 0, 0, 0.08)');
    this.setFocus('a', 'rgba(0, 0, 0, 0.08)');
    this.setFocus('link-text', 'rgba(0, 0, 0, 0.08)');
  }

  setFocus(className, color) {
    var input = document.getElementsByClassName(className);
    Array.from(input).forEach(x => x.addEventListener("focus", function () {
      this.style.backgroundColor = color;
    }));
    Array.from(input).forEach(x => x.addEventListener("blur", function () {
      this.style.backgroundColor = "unset";
    }));
  }

  hideFrame: boolean;
  watingimageUrl = '';
  hidaAllData = false;
  swipeDownFlag = false;
  years = [];
  menuFilter: MenuFilter[] = []
  @ViewChild('multiUsers') multiUsers: SelectComponent
  updateYearService = true;
  menuItems: MenuItem[];
  deviceType: string;
  deviceId: any;
  isBiomericLogin = false;
  swipeDownPages = ['/Messages']
  constructor(
    private postSrv: PostService,
    private router: Router,
    private loader: LoaderService,
    private sidenavService: SidenavService,
    public user: UserService,
    public mobileService: MobileService,
    private http: HttpClient,
    private translate: TranslateService,
    private commonSrv: CommonService,
    private countersSrv: HeaderCountersService,
    private sharedService: PupilCardSharedService,
    private rsaService: RsaService,
    private matDialog: MatDialog,
    public shared: SharedService,
    private cookieService: CookiesService,
    private gradeService: GradesService,
    private hebrewYear: HebrewYearPipe,
    private serverService: ServerService,
    private sqlService: SqlService,
    private _location: Location

  ) {

    this.deviceType = cookieService.getItem('deviceType');
    this.sqlService.getByKey('deviceId').subscribe(result => {
      if (result != undefined) {
        this.deviceId = result['value'];
      }
    });
    this.user.getBioLogin().subscribe(result => {
      this.isBiomericLogin = !shared.isEmpty(result);
    });


      serverService.getLoadComplete().subscribe(result => {
        this.swipeDownFlag = false;
      });
      this.updateAvatar();
      this.initStudyYears();

      if (this.localUser !== null) {

        this.isSideNavOpen = !(localStorage.getItem('menu_open') === 'false');
        this.subscription.push(
          this.loader.onLoadingChanged.subscribe(isLoading => {
            setTimeout(() => this.loading = isLoading, 1);
          }));
        this.subscription.push(this.countersSrv.getUnreadMessages().subscribe(data => {
          this.counters.unreadMessages = data;
        }));
        this.subscription.push(this.countersSrv.getUnreadNotificatoins().subscribe(data => {
          this.counters.unreadNotifications = data;
        }));
        this.subscription.push(
          this.user.getSelectedStudyYear().subscribe(year => {
            this.updateYearService = false;
            this.selectedYear.setValue(year);
          }));
        this.subscription.push(
          this.mobileService.isMobile$.subscribe(ismobile => {
            if (this.menuFilter.length > 0) {
              if (this.mobileService.isMobileView) {
                this.pageSettings.menuItems = this.menuItems.filter(x => !this.menuFilter.filter(mf => !mf.showOnMobile).map(m => m.id).includes(x.id));
              } else {
                this.pageSettings.menuItems = this.menuItems.filter(x => !this.menuFilter.filter(mf => !mf.showOnWeb).map(m => m.id).includes(x.id));
              }
            }

          })
        );
        this.subscription.push(router.events.subscribe((event: NavigationStart) => {
          if (event.navigationTrigger === 'popstate') {
            if (event.url == '/empty')
              _location.back();
            //router.navigate(['dashboard']);
            //_location.

            // Perform actions
          }
        }));


      }
    }

  ngAfterContentChecked() {
    this.documentlocationpathname = document.location.pathname.split('/')[1];
    this.documentlocationsubpathname = document.location.pathname.split('/')[2];
    if ((this.documentlocationsubpathname == 'studentGrades'))
      document.getElementsByClassName('innerItem')[0]?.children[0].classList.add('active');
  }

  showFrame = '';
  route: string;
  interval;
  selected: string;
  pageSettings: MenuDate;
  isSideNavOpen: boolean;
  messagesIcon: MenuItem;
  notificationIcon: MenuItem;
  helpIcon: MenuItem;
  unreadMessagesPreview: MessageItem[];
  unreadNotificationPreview: NotificationData[];
  counters: Countres = new Countres();
  popupOpen = false;
  trigger: number = 0;
  previousUser = '';
  documentlocationpathname = '';
  documentlocationsubpathname = '';
  selectedYear: UntypedFormControl = new UntypedFormControl(2019);
  subscription: Subscription[] = [];
  @ViewChild('drawer', { read: MatSidenav, static: true }) public drawer: MatSidenav;
  @ViewChild('messagesPreviewMenu') messagesPreview: MatMenuTrigger;
  @ViewChild('notificationsPreviewMenu') notificationsPreviewMenu: MatMenuTrigger;

  @ViewChild('unreadMessage') unreadMessage: UnreadMessagesPreviewComponent;
  @ViewChild('unreadNotifications') unreadNotifications: UnreadMessagesPreviewComponent;
  @ViewChild('unreadNotificationPreviewMenu') unreadNotificationPreviewMenu: UnreadMessagesPreviewComponent;
  @ViewChild('t') profileDDL: MatMenuTrigger;
  @ViewChild('personalDetails') personalDetails: ElementRef;

  localUser: User = new User();
  loading = false;

  initStudyYears() {
    for (let year = environment.currentYear; year >= environment.viewSmartModulesFromYear; year--) {
      this.years.push({
        year: this.hebrewYear.transform(year),
        value: year
      });
    }
    var prevYear = sessionStorage.getItem("studyYear");
    if (prevYear) {
      this.changeSelectedStudyYear(Number(prevYear));
    }
    else {
      this.selectedYear.setValue(this.years[0].value);
    }
  }

  changeSelectedStudyYear(selectedYear: number) {
    sessionStorage.setItem("studyYear", selectedYear.toString());
    this.selectedYear.setValue(selectedYear);
    this.user.updateSelectedStudyYear(selectedYear, this.postSrv);
  }

  profileDDLOpenMenu() {
    this.profileDDL.openMenu();
    this.personalDetails.nativeElement.focus();
  }

  close() {
    if (this.mobileService.isMobileView) {
      this.drawer?.toggle();
    }
  }

  checkLastPost() {

    setTimeout(() => {
      let now = new Date();
      let diff = (now.getTime() - Number(localStorage.getItem('lastPost'))) / 1000;
      if (diff > 900 && this.isBiomericLogin == true) {
        window.location.href = window.location.origin + "/account/login";
      }
      else if (diff > 14400) {
        window.location.reload();
      } else {
        this.checkLastPost();
      }
    }, 10000);
  }

  onSwipe(toopen: boolean, swipeData: any) {
    if (toopen && swipeData.startPoint <= 32) {
      this.drawer.open();
    }
    else this.drawer.close();
  }



  ngOnInit() {
    if (this.user.getMustUpdatePassword()
      && !this.router.url.toLowerCase().startsWith('/account')
    ) {
      this.user.clear();
      this.disconnect();

    }

    this.hideFrame = this.user.getHideFrame();

    this.sidenavService.setSidenav(this.drawer);
    if (this.mobileService.isMobileView) {
      this.user.updateLastToken();
    }

    const institutionCode = this.shared.getParamValueFromQuery('institutionCode');
    const csUser = this.shared.getParamValueFromQuery('csUser');
    const code = this.shared.getParamValueFromQuery('code');
    if (this.shared.isEmpty(institutionCode) || this.shared.isEmpty(csUser) || this.shared.isEmpty(code)) {
      this.loadMultiUsers();
      this.loadMenuItems();
    }

    this.startTimer();
    this.checkActiveTab();
    this.checkLastPost();

  }

  startTimer() {
    this.interval = setInterval(() => {
      this.checkLastToken();
    }, 30000);
  }

  checkActiveTab() {
    this.interval = setInterval(() => {
      if (sessionStorage.getItem('tabId') !== localStorage.getItem('lastTabId')
        && localStorage.getItem('lastTabId') != null
      ) {
        sessionStorage.setItem('tabId', localStorage.getItem('lastTabId'))
        window.location.href = window.location.origin;
      }
    }, 5000);
  }


  pauseTimer() {
    clearInterval(this.interval);
  }

  checkLastToken() {
    if (!this.user.needToCheckToken()) {
      return;
    }

    this.user.getSavedUser().subscribe(result => {
      let savedUser = '';
      if (result != undefined) {
        savedUser = this.rsaService.decrypt(result['value']).split('***')[0];
      }
      if (this.mobileService.isMobileView && this.user.checkLastToken()) {
        const userName = savedUser;
        if (userName !== undefined && userName !== '') {
          this.user.updateLastToken();
          this.routeToUrl(this.router.url.split('?')[0]);
        }
      }
      if (!this.mobileService.isMobileView && this.user.checkLastToken() && !this.popupOpen) {
        this.popupOpen = true;
        const dialogRef = this.matDialog.open(DisconnectedAlertComponent, {
          disableClose: true,
          data: {},
          autoFocus: true
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe((result: any) => {
          this.popupOpen = false;
          if (result === false) {
            this.disconnect();
          } else {
            this.user.updateLastToken();
          }
        });
      }
    });
  }

  disconnect() {
    try {
      this.user.getSavedUser().subscribe(result => {
        let token = '';
        if (result != undefined && !this.shared.isEmpty(result['value'])) {
          token = result['value'];
        }
        this.user.getBioLogin().subscribe(bioResult => {
          if (this.shared.isEmpty(token) && bioResult != undefined && !this.shared.isEmpty(bioResult['value'])) {
            token = bioResult['value'];
          }
          this.postSrv.postData('api/user/LogOut', true, { 'id': token }).pipe(take(1)).subscribe(() => {
            const moeDisconnected = this.disconnectFromMoe();
            this.user.clear();
            sessionStorage.removeItem("studyYear");
            if (!moeDisconnected) {
              this.user.removeBioLogin().subscribe(res => {
                this.user.clearSavedUser().subscribe(res2 => {
                  window.location.href = window.location.origin;
                })
              });
            }
          });
        });
      });
    } catch {
      this.user.clear();
      this.router.navigate(['/account/login']);
    }
  }

  disconnectFromMoe(): boolean {
    const loginMoe = this.cookieService.getItem('loginMoe');
    if (!this.shared.isEmpty(loginMoe)) {
      window.location.href = environment.loginMinstry + '?logout=true';
      return true;
    }
    return false;
  }

  async fetchData() {

  }

  loadMenuItems() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.http.get('/assets/version/menuitems.json?dt=' + (new Date()).getTime(), { headers, responseType: 'text' }).subscribe(data => {
      this.menuFilter = (JSON.parse(data) as MenuFilter[]);
      this.loadMenuItemsStep2();
    });
  }

  loadMenuItemsStep2() {
    if (this.pageSettings)
      this.pageSettings.menuItems = [];
    this.hidaAllData = false;

    this.postSrv.postData('api/Menu/GetInitData',
      true,
      { 'id': true }
    ).pipe(take(1)).subscribe((result: baseResult<MenuDate>) => {
      this.hideFrame = this.user.getHideFrame();
      //alert('loadMenuItems' + result.data)

      if (result.data) {
        this.pageSettings = result.data;
        if (this.pageSettings.menuItems?.filter(x => x.newWindow && x.id !== 26 && x.id !== 13).length > 0) {
          const externalSites = new MenuItem();
          externalSites.id = 9999;
          externalSites.title = this.translate.instant('externalSites');
          externalSites.isMobileIcon = 'externalSites';
          externalSites.newWindow = false;
          externalSites.isMobileEnabled = true;
          externalSites.viewOrder = 17;
          externalSites.viewOrder2 = 17.2;
          this.pageSettings.menuItems.push(externalSites);
        }
        this.pageSettings.menuItems = this.pageSettings.menuItems?.filter(x => !x.newWindow || x.id === 26 || x.id === 13 || x.id === 42).sort((a, b) => {
          if (a.viewOrder2 !== b.viewOrder2) {
            return a.viewOrder2 - b.viewOrder2;
          }
          if (a.viewOrder !== b.viewOrder) {
            return a.viewOrder - b.viewOrder;
          }
          return b.id - a.id;
        });
        if (this.pageSettings.menuItems?.filter(x => x.id === 42).length > 0) {
          this.pageSettings.menuItems.filter(x => x.id === 42)[0].isMobileIcon = 'SmartScreen';
        }

        this.menuItems = this.pageSettings.menuItems;

        if (this.menuFilter.length > 0) {
          if (this.mobileService.isMobileView) {
            this.pageSettings.menuItems = this.pageSettings.menuItems.filter(x => !this.menuFilter.filter(mf => !mf.showOnMobile).map(m => m.id).includes(x.id));
          } else {
            this.pageSettings.menuItems = this.pageSettings.menuItems.filter(x => !this.menuFilter.filter(mf => !mf.showOnWeb).map(m => m.id).includes(x.id));
          }
        }
        let allowToNavigate = this.allowToNavigate(this.router.url, this.pageSettings.menuItems);
        if (!allowToNavigate) {
          this.router.navigate(['/error/notAllowed']);
          //return;
        }
        this.countersSrv.updateMenuItems(this.pageSettings.menuItems);
        this.countersSrv.updateHasWebtop(this.pageSettings.hasWebtop);
        this.helpIcon = this.pageSettings.menuItems?.find(x => x.id === 13);
        this.messagesIcon = this.pageSettings.menuItems?.find(x => x.id === 3);
        this.notificationIcon = this.pageSettings.menuItems?.find(x => x.id === 59);
        this.countersSrv.updateUnreadMessages(result.data.counters.unreadMessages);
        this.countersSrv.updateUnreadNotifications(result.data.counters.unreadNotifications);
        this.hideFrame = this.user.getHideFrame();
      }
    });
  }

  allowToNavigate(urlToCheck, menuItems = null) {
    if (urlToCheck.indexOf('?') > -1) {
      urlToCheck = this.router.url.slice(0, this.router.url.indexOf('?'));
    }
    if (menuItems?.length > 0) {
      return urlToCheck.toLowerCase().startsWith('/dashboard') || urlToCheck.toLowerCase().startsWith('/error') || urlToCheck.toLowerCase().startsWith('/account')
        || urlToCheck.toLowerCase().startsWith('/empty') || urlToCheck.toLowerCase().startsWith('/push')
        || menuItems.filter(x => x.isMobileIcon === urlToCheck.split('/')[1]).length > 0;
    } else {

      return this.countersSrv.getMenuItems().subscribe(items => {
        if (items == null || items.length === 0) return false;
        return urlToCheck.toLowerCase().startsWith('/dashboard') && !urlToCheck.toLowerCase().startsWith('/empty')
          && !urlToCheck.toLowerCase().startsWith('/push') && !urlToCheck.toLowerCase().startsWith('/error') && !urlToCheck.toLowerCase().startsWith('/account')
          && items.filter(x => x.isMobileIcon === urlToCheck.split('/')[1]).length === 0;
      });
    }

  }

  openSettings(selectedModule?: number) {
    this.close();
    const dialogRef = this.matDialog.open(SettingsComponent,
      {
        panelClass: 'settings-dialog',
        data: { selectedItem: selectedModule }
      });
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.updateAvatar();
    });

  }
  updateAvatar() {
    this.localUser = this.user.getUser();
    if (this.localUser != null) {
      let userType = this.localUser.userType;

      if (userType == 1 || userType == 2) {
        this.watingimageUrl = this.shared.getImageParam(this.localUser?.userId, this.localUser?.userImageToken,
          this.localUser?.institutionCode, false, userType) + '&ts=' + (new Date).getTime();
      }
      else {
        this.watingimageUrl = this.shared.getImageParam(this.localUser?.userId, this.localUser?.userImageToken,
          this.localUser?.institutionCode, 1) + '&ts=' + (new Date).getTime();
      }
    }
  }

  updateCounters(): void {
    if (this.mobileService.isMobileView) {
      this.close();
    }
    this.sharedService.updateNotificationData(undefined);
    this.postSrv.postData('api/Menu/GetMenuCounters',
      true,
      {}
    ).pipe(take(1)).subscribe((result: baseResult<Countres>) => {
      if (result.data) {
        this.countersSrv.updateUnreadMessages(result.data.unreadMessages);
        this.countersSrv.updateUnreadNotifications(result.data.unreadNotifications);
      }
    });
  }

  getUnreadMessagesPreview(): void {
    this.unreadMessagesPreview = [];
    this.postSrv.postData<MessageBoxInbox[]>('api/Menu/GetPreviewUnreadMessages',
      true,
      {}).pipe(take(1)).subscribe((result: baseResult<MessageBoxInbox[]>) => {
        if (result.status) {
          this.unreadMessagesPreview = result.data.map((item) =>
            new MessageItem({
              shortName: item.student_L_name.charAt(0) + item.student_F_name.charAt(0),
              lastName: item.student_L_name,
              privateName: item.student_F_name,
              fileAttached: item.filesWereAttached,
              messageId: item.messageId,
              sendingDate: item.sendingDate,
              subject: item.subject,
              senderId: item.senderId,
              hasread: item.hasRead === 1,
              recipientsList: '',
              userImageToken: item.userImageToken,
              selected: false,
              title: (item.userTitle !== null ?
                item.userTitle :
                (item.classCode !== undefined && item.classCode !== '0' ?
                  this.commonSrv.getClassName(item.classCode, item.classNumber) :
                  (item.userType !== undefined && item.userType < 4 ? this.translate.instant('UserType' + item.userType) : '')))
            }));
          this.messagesPreview.openMenu();
          this.unreadMessage.menuOpened();
        }

      });
  }

  closeMenu() {
    this.profileDDL?.closeMenu();
    this.messagesPreview?.closeMenu();
    this.notificationsPreviewMenu?.closeMenu();
  }

  getUnreadNotificationPreview(): void {
    this.unreadNotificationPreview = [];
    this.postSrv.postData<NotificationData[]>('api/Menu/GetPreviewUnreadNotifications',
      true,
      {}).pipe(take(1)).subscribe((result: baseResult<NotificationData[]>) => {
        if (result.status) {
          this.unreadNotificationPreview = result.data;
        }
        this.notificationsPreviewMenu.openMenu();
        this.unreadNotifications.menuOpened();
      });
  }

  getUnreadNotificationsPreview(): void {
    this.unreadMessagesPreview = [];
    this.postSrv.postData<NotificationData[]>('api/Menu/GetPreviewUnreadNotifications',
      true,
      {}).pipe(take(1)).subscribe((result: baseResult<NotificationData[]>) => {
        if (result.status) {
          this.unreadNotificationPreview = result.data;
        }
      });
  }
  routeToUrl(url: string) {
    this.router.navigate(['/empty'])
      .then(() => {
        this.router.navigate([url]);
      });
  }
  pushpinSidenav() {
    this.isSideNavOpen = !this.isSideNavOpen;
    localStorage.setItem('menu_open', JSON.stringify(this.isSideNavOpen));
  }

  loadMultiUsers(flag = false, selectedUser: string = '') {
    this.postSrv.postData<MultiUsers[]>('api/user/GetMultipleUsersForUser', true, '{}')
      .pipe(take(1))
      .subscribe(multiUsers => {
        const usersList = (multiUsers.data as MultiUsers[])?.map(item => ({
          value: item.studentId,
          viewValue: this.getTitle(item),
          extraData: item
        }));
        this.user.setMultiUsers(usersList);
        if (usersList && usersList.length > 0) {
          if (!this.shared.isEmpty(selectedUser)) {
            this.selected = selectedUser;
          } else {
            const lastUser = this.user.getSelectedUser();
            if (lastUser !== undefined && lastUser !== null && usersList.filter(x => x.value === lastUser).length === 1) {
              this.selected = lastUser;
            } else {
              this.selected = usersList[0].value;
            }
          }
          this.user.setSelectedUser(this.selected);
        }
      });
  }

  getUserType(userType: number): string {
    switch (userType) {
      case 0: return this.translate.instant('studentOf');
      case 1: return this.translate.instant('teacherIn');
      case 2: return this.translate.instant('parentIn');
    }

    return '';
  }

  getTitle(item: MultiUsers) {
    if (item.isSchoolyAdministrator === 1) { return this.translate.instant('managerOf') + item.school_name + ' (' + item.institutionCode + ')'; }
    return this.getUserType(item.isTeacher) + item.school_name + ' (' + item.institutionCode + ')';
  }
  BackToMange() {
    this.close();
    this.gradeService.updateSelectedGradeHeader(null);
    //const admin =  this.rsaService.decrypt( localStorage.getItem('admin')).split('@!!@');

    this.postSrv.postData<boolean>(
      'api/user/BackToMange',
      true,
      {}
    ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
      if (result.status) {
        const admin = localStorage.getItem('admin').split('@!!@');

        this.user.setUser(JSON.parse(admin[0]) as User, this.postSrv);
        this.user.setSelectedUser(admin[1]);
        this.loadMultiUsers(true);
        this.loadMenuItems();
        localStorage.removeItem('admin');

        this.router.navigate(['empty'])
          .then(() => {
            let paramArr = JSON.parse(localStorage.getItem('lastManage'));
            this.router.navigate(['userManagment'], { queryParams: { ...paramArr } });
            localStorage.removeItem('lastManage');

          });

        this.updateAvatar();
      }
    });

  }
  checkAdmin() {
    return localStorage.getItem('admin');
  }

  usertypeChange(selectedValue, userType = null, institutionCode = null) {
    this.previousUser = this.selected;
    const isWebTopUser = this.user.getUser().isWebTopUser;
    this.selected = selectedValue;
    this.closeMenu();
    this.close();
    this.postSrv.postData<User>(
      'api/user/ChangeUser',
      true,
      {
        'StudentId': this.selected,
        'institutionCode': institutionCode,
        'userType': userType
      }
    ).pipe(take(1)).subscribe((result: baseResult<User>) => {
      if (result.status) {
        this.selected = result.data.id;
        this.setDeviceId();
        if (result.data.isReseted == true && localStorage.getItem('admin') == null) {
          const dialogRef = this.matDialog.open(FirstPasswordDialogComponent,
            {
              panelClass: 'settings-dialog',
              data: { 'schoolName': result.data.schoolName },
              disableClose: true
            });
          dialogRef.afterClosed().pipe(take(1)).subscribe((resultRef: any) => {

            if (resultRef !== false) {
              this.setUserAfterChange(result, isWebTopUser)

            } else {
              this.selected = this.previousUser;
              let tmp = this.user.getMultiUsers();
              this.multiUsers.setSelectedItem(tmp.filter(x => x.value == this.selected)[0]);
              this.postSrv.postData<User>(
                'api/user/ChangeUserAfterCancel',
                true,
                { 'StudentId': this.selected }
              ).pipe(take(1)).subscribe();
            }
          });
        } else {
          this.setUserAfterChange(result, isWebTopUser, userType == null)
        }

      }
    });
  }

  setDeviceId() {
    if (this.shared.isEmpty(this.deviceId)) { return; }
    this.postSrv.postData('api/user/setRegistrationId',
      true,
      {
        'param1': this.deviceType,
        'param2': this.deviceId
      }
    ).pipe(take(1)).subscribe((result: baseResult<any>) => {
    });
  }

  setUserAfterChange(result, isWebTopUser, navigate = true) {
    result.data.isWebTopUser = isWebTopUser;
    this.user.setUser((result.data as User), this.postSrv);
    this.user.setSelectedUser(this.selected);
    this.loadMenuItems();
    if (!navigate) return;
    this.router.navigate(['/empty'])
      .then(() => {
        this.router.navigate(['/dashboard']);
      });
  }

  ngOnDestroy() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }

  openHelp() {
    window.open(this.helpIcon.moduleFile, '_blank');
  }

  ngAfterViewInit(): void {

  }
  swipeDown() {
    if (!this.swipeDownPages.includes(window.location.pathname)) return;
    this.swipeDownFlag = true;
    setTimeout(() => {
      this.swipeDownFlag = false;
    }, 2000);
  }

  myRefreshEvent($event, refresh) {
    console.log($event)
    console.log(refresh)
  }
  alert(loadmore) {

    console.log(loadmore)

  }
}
